<template>
  <br />
  <!-- RELATÓRIO -->
  <div class="row">
    <div class="col-md-4 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-success"><i style="color: white" class="fas fa-solid fa-users"></i></span>
        <div class="info-box-content">
          <span class="info-box-text">Enviados</span>
          <span class="info-box-number">{{ total_enviados }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-info"><i style="color: white" class="fas fa-solid fa-users"></i></span>
        <div class="info-box-content">
          <span class="info-box-text">Aguardando</span>
          <span class="info-box-number">{{ envio.total_contatos - total_enviados }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-danger"><i style="color: white" class="fas fa-solid fa-users"></i></span>
        <div class="info-box-content">
          <span class="info-box-text">Inválidos</span>
          <span class="info-box-number">{{ envio.calculo_invalidos }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- DADOS DO ENVIO -->
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" id="accordion">Detalhes do envio</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6">
          <!-- FORM -->
          <div class="form-group">
            <label for="exampleInputEmail1">Nome do Envio</label>
            <input
              :disabled="!editar_envio"
              class="form-control"
              id="exampleInputEmail1"
              placeholder="Digite o nome do seu envio"
              v-model="envio.nome"
            />
          </div>

          <div class="form-group">
            <label for="exampleSelectRounded0">Tempo de envio</label>
            <select @change="alterarTempo()" class="custom-select rounded-0" v-model="tipo_tempo_envio">
              <option value="Aquecimento">Aquecimento (40s - 60s)</option>
              <option value="Envio Rapido">Envio Rapido (12s - 20s)</option>
              <option value="Recomendado">Recomendado (20s - 40s)</option>
              <option value="Personalizado">Personalizado</option>
            </select>
          </div>

          <div class="row" v-if="tipo_tempo_envio == 'Personalizado'" style="margin-bottom: 10px">
            <div class="col-md-6 col-sm-6 col-12">
              <label for="exampleInputEmail1">Tempo mínimo</label>
              <input class="form-control" id="exampleInputEmail1" v-model="minTime" type="number" @keyup="atualizarTempoBD()" />
            </div>
            <div class="col-md-6 col-sm-6 col-12">
              <label for="exampleInputEmail1">Tempo máximo</label>
              <input class="form-control" id="exampleInputEmail1" v-model="maxTime" type="number" @keyup="atualizarTempoBD()" />
            </div>
          </div>

          <div v-if="!envio_concluido" class="form-group">
            <div class="form-check">
              <input v-model="exibir_detalhes" type="checkbox" class="form-check-input" id="exibir_contatos" @click="show" />
              <label class="form-check-label" for="preview">Exibir detalhes</label>
            </div>
          </div>

          <div v-if="exibir_detalhes">
            <div class="form-group">
              <label for="exampleSelectRounded0">Tipo de Envio</label>
              <select :disabled="!editar_envio" class="custom-select rounded-0" id="exampleSelectRounded0" v-model="tipo_envio">
                <option value="texto">Texto</option>
                <option value="imagem">Imagem</option>
                <option value="video">Video</option>
                <option value="link">Link</option>
                <option value="voz">Voz</option>
              </select>
            </div>

            <div v-if="tipo_envio != 'voz'">
              <div class="form-group">
                <label>Mensagem</label>
                <textarea
                  :disabled="!editar_envio"
                  class="form-control"
                  rows="3"
                  placeholder="Digite a mensagem que deseja enviar ..."
                  v-model="envio.mensagem"
                ></textarea>
              </div>
            </div>

            <div v-if="tipo_envio != 'texto'">
              <div class="form-group">
                <label for="imageToSend">Url da mídia</label>
                <textarea
                  :disabled="!editar_envio"
                  class="form-control"
                  placeholder="Url da mídia a ser enviada"
                  v-model="envio.url"
                  id="imageToSend"
                  rows="3"
                />
                <a href="/gallery" target="_blank" style="font-size: 15px">Não possui url? Busque na sua galeria.</a>
              </div>
              <div class="form-group">
                <div v-if="envio.url" class="form-check">
                  <input v-model="preview" type="checkbox" class="form-check-input" id="preview" />
                  <label class="form-check-label" for="preview">Previsualizar?</label>
                </div>
              </div>
              <div v-if="preview" class="form-check">
                <img id="qrCode" :src="envio.url" style="min-width: 300px; min-height: 200px; max-width: 300px; max-height: 200px" />
              </div>
            </div>

            <div v-if="tipo_envio == 'video'">
              <div v-if="preview" class="form-check">
                <video v-if="preview" controls width="250" height="150">
                  <source :src="envio.url" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div v-if="tipo_envio == 'voz'">
              <div class="form-group">
                <br />
                <video v-if="envio.url" controls width="250" height="150" poster="{{ asset('/dist/img/whats-logo.png') }}">
                  <source :src="envio.url" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div v-if="!envio_concluido" class="form-group">
              <button v-if="!editar_envio" @click="editar_envio = true" class="btn btn-flat btn-info" style="border-radius: 10px">
                Editar Envio
              </button>

              <button v-if="editar_envio" @click="confirmarEdicao()" class="btn btn-flat btn-success" style="border-radius: 10px">
                Salvar alterações
              </button>

              <button v-if="editar_envio" @click="editar_envio = false" class="btn btn-flat btn-danger" style="border-radius: 10px">Cancelar</button>
            </div>
          </div>

          <div v-if="!envio_concluido" class="form-group">
            <div class="form-check">
              <input v-model="exibir_contatos" type="checkbox" class="form-check-input" id="exibir_contatos" @click="show" />
              <label class="form-check-label" for="preview">Exibir contatos pendentes?</label>
            </div>
          </div>

          <div class="form-group">
            <label v-if="exibir_contatos">Números pendentes</label>
            <textarea
              placeholder="Nenhum contato pendente..."
              v-model="lista_de_contatos"
              id="textToSend"
              rows="4"
              cols="40"
              disabled="true"
              class="form-control"
              v-if="exibir_contatos"
            />
          </div>
        </div>
        <!-- AVISOS E MENSAGENS -->
        <div class="col-sm-12" style="line-height: 15px">
          <h4 v-if="!envio_concluido" style="font-size: 15px; font-weight: bold">{{ message }}</h4>

          <!-- 

  <br />
  <h4 style="font-size: 15px">Loop: {{ loop }}</h4>
  <h4 style="font-size: 15px">Passo: {{ parcial }} de {{ totalMatriz }}</h4>
  <div style="font-size: 15px" v-html="messageRemetente"></div>
  <h4 style="font-size: 15px; font-weight: bold">{{ message }}</h4>
  <br />
  <div v-if="messageResposta" style="font-size: 15px" v-html="messageResposta"></div>
  <h4 v-if="messageRespostaTempo" style="font-size: 15px; font-weight: bold">{{ messageRespostaTempo }}</h4>
  -->

          <div v-if="envio_concluido">
            <h3 class="message" style="color: green">Envio concluído</h3>

            <a href="/send/continue" class="btn btn-success">Listar envios abertos</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LOGS DE ERRO -->
  <div class="card" v-if="logsErro && logsErro.length">
    <div class="card-header">
      <button style="float: left; margin-top: -5px" type="button" class="btn btn-tool" data-toggle="collapse" href="#collapse2">
        <i class="fas fa-minus"></i>
      </button>
      <h3 class="card-title" id="accordion2">
        Logs de erro
        <span class="right badge badge-danger">{{ logsErro.length }}</span>
      </h3>
    </div>
    <div id="collapse2" class="collapse" data-parent="#accordion2">
      <div class="card-body" style="padding: 0px 0px 0px 0px">
        <div class="row">
          <div class="col-sm-12">
            <table>
              <thead>
                <tr>
                  <th scope="col" style="width: 10%">Logs</th>
                  <th scope="col" style="width: 10%">Data</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in logsErro" :key="logs" :value="logs">
                  <td data-label="Logs">
                    <div v-html="log.log"></div>
                  </td>
                  <td data-label="Data">
                    {{ formatDate(log.created_at) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LOGS DE ATIVIDADE -->
  <div class="card card-primary" v-if="logs && logs.length">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12">
          <label for="exampleSelectRounded0">Logs de atividade</label>
          <table>
            <thead>
              <tr>
                <th scope="col" style="width: 10%">Logs</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="log in logs" :key="logs" :value="logs">
                <td data-label="Logs">
                  <div v-html="log"></div>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </div>
  </div>

  <!-- CONEXÕES -->
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Conexões</h3>
    </div>
    <div class="card-body" style="padding: 0px 0px 0px 0px">
      <div class="row">
        <div class="col-sm-12">
          <table>
            <thead>
              <tr>
                <th style="width: 5%"><input v-model="allSelected" type="checkbox" v-on:click="selectAll()" /></th>
                <th scope="col" style="width: 30%">conexões</th>
                <th scope="col">ID</th>
                <th scope="col">Tags</th>
                <th scope="col">Status</th>
                <th scope="col">Enviadas</th>
                <!-- <th scope="col" style="width: 30%">Conexões</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="container in auxContainers"
                :key="container"
                :value="container"
                :class="{ enviando: remetente.id == container.id, recebendo: container_selecionado && container_selecionado.id == container.id }"
              >
                <td data-label="Região">
                  <input type="checkbox" :id="container.id" :value="container" v-model="conexoes_selecionadas" />
                </td>
                <td data-label="Nome">
                  {{ container.nome }}
                </td>
                <td data-label="Nome">
                  {{ container.id }}
                </td>
                <td data-label="Tags">
                  <span v-for="(tag, index) in container.tags" :style="'margin-right:5px;color: white;background-color:' + tag.cor" class="badge">
                    {{ tag.tag }}
                  </span>
                </td>
                <td data-label="Status">
                  <connection-status :my_container="container" :usuario="container.usuario" />
                </td>
                <td data-label="Enviado">
                  {{ container.enviados }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-sm-12" style="margin-left: 8px">
          <br />
          <div class="form-group">
            <button v-if="!envio_concluido && continuar_envio" @click="preparaCombinacao()" class="btn btn-success">Iniciar envio</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import sendController from "../controller/sendController";
import moment from "moment";

export default {
  props: [
    "my_container",
    "blacklists",
    "containers",
    "usuario",
    "funis",
    "mensagens_padrao",
    "contatos_para_envio",
    "id_mensagem_padrao",
    "aux_list_break",
    "nao_redirecionar",
    "aux_meta_dados",
    "editar",
    "cancelar",
    "envio",
  ],

  mixins: [wppController, sweetAlert, Swal, sendController],

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      url_to_send: null,
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      funil_selecionado: "",
      tipo_envio: "",
      mensagem_selecionada: "",
      envio_para_grupos: 0,
      blacklist_selecionada: "",
      contador_blacklist: 0,
      dont_remove_duplicated: false,
      conexoes_selecionadas: [],
      resultadoParAPar: [],
      resultadoEmbaralhado: [],
      remetente: [],
      destinatario: [],
      message: "",
      messageRemetente: "",
      messageResposta: "",
      minTime: 3,
      maxTime: 5,
      loop: 0,
      parcial: 0,
      totalMatriz: 0,
      auxContainers: [],
      qtdenviado: [],
      qtdrecebido: [],
      arrayContainers: [],
      logs: [],
      logsErro: [],
      editar_script_conversa: false,
      script_conversa: "",
      conversa: "",
      cont_logs: 0,
      cont_logs_erro: 0,
      timeToSendResposta: 0,
      messageRespostaTempo: 0,
      maxTimeResposta: 5,
      minTimeResposta: 3,
      allSelected: false,
      editar_envio: false,
      continuar_envio: true,
      envio_concluido: false,
      exibir_contatos: false,
      exibir_detalhes: false,
      tipo_tempo_envio: "Envio Rapido",
      conexoes_embaralhadas: "",
      indice_conexao_atual: "",
      container_selecionado: "",
      contatos: [],
      funil: "",
      lista_de_contatos: "",
      index: 0,
      total_enviados: 0,
      tipo_envio: "",
      qtd_loops_simulacao: 2,
    };
  },

  mounted() {
    if (this.containers) {
      this.container = this.containers[0];
    } else {
      this.container = this.my_container;
    }
    this.auxContainers = this.containers;

    for (let i = 0; i < this.auxContainers.length; i++) {
      console.log("entrei no laço");
      this.auxContainers[i].enviados = 0;
      this.auxContainers[i].recebidos = 0;
      this.auxContainers[i].usuario = { porta: this.auxContainers[i].user_porta };
      this.auxContainers[i].tags = JSON.parse(this.auxContainers[i].tags);
    }

    console.log("Envio");
    console.log(this.envio);

    this.tipo_envio = this.envio.tipo_envio.toLowerCase();

    this.tipo_tempo_envio = this.envio.tempo_envio;

    if (this.envio.tempo_min_envio) {
      this.minTime = this.envio.tempo_min_envio;
    }

    if (this.envio.tempo_max_envio) {
      this.maxTime = this.envio.tempo_max_envio;
    }

    this.alterarTempo();

    //INICIALIZANDO ENVIO
    this.buscarNumerosNaoEnviados(this.envio.id, (numeros) => {
      if (this.envio.funil_id) {
        this.buscarFunil();
      } else {
        console.log("Não buscando funil");
        this.funil = undefined;
      }

      this.contatos = numeros;

      this.total_enviados = this.envio.total_contatos - this.contatos.length;

      console.log("Total enviados");
      console.log(this.total_enviados);
      console.log("Total pendentes");
      console.log(this.contatos.length);
      console.log(this.total_enviados);
      console.log("Total contatos");
      console.log(this.contatos);

      this.loading = false;

      //FORMATANDO LISTA DE CONTATOS PARA EXIBIR
      for (let i = 0; i < this.contatos.length; i++) {
        this.contatos[i].phone = this.contatos[i].numero + "@c.us";

        //REMOVENDO ESPAÇO EM BRANCO INICIAL
        if (i == 0) {
          this.lista_de_contatos = this.lista_de_contatos + this.contatos[i].numero;
        } else {
          this.lista_de_contatos = this.lista_de_contatos + "  " + this.contatos[i].numero;
        }

        if (i + 1 != this.contatos.length) {
          this.lista_de_contatos + ",";
        }
      }

      if (this.contatos.length <= 0) {
        this.envio_concluido = true;

        this.finalizarEnvio(this.envio.id);
      }
    });
  },

  methods: {
    buscarFunil() {
      console.log("Buscando funil " + this.envio.funil_id);

      let data = {
        funil_id: this.envio.funil_id,
      };

      axios
        .post(`/funil/search`, data)
        .then((response) => {
          this.funil = response.data.funil;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    alterarTempo() {
      if (this.tipo_tempo_envio == "Recomendado") {
        this.minTime = 20;
        this.maxTime = 40;
      } else if (this.tipo_tempo_envio == "Aquecimento") {
        this.minTime = 40;
        this.maxTime = 60;
      } else if (this.tipo_tempo_envio == "Envio Rapido") {
        this.minTime = 12;
        this.maxTime = 20;
      } else if (this.tipo_tempo_envio == "Personalizado") {
      }

      this.atualizarTempoBD();
    },

    atualizarTempoBD() {
      let data = {
        id: this.envio.id,
        tempo_envio: this.tipo_tempo_envio,
        tempo_max_envio: this.maxTime,
        tempo_min_envio: this.minTime,
      };

      console.log(data);

      axios
        .post(`/user/envio/update_tempo`, data)
        .then((response) => {
          console.log("Envio atualizado no banco");
        })
        .catch((error) => {
          console.log("Falha ao alterar tempo no banco");
          console.log(error);
        });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    },

    sendMessage() {
      this.continuar_envio = false;

      this.timeToSend = this.getTime();

      console.log("Tempo inicial - " + this.timeToSend);

      let that = this;

      console.log("Container selecionado");
      console.log(this.container_selecionado);

      for (let i = 0; i <= this.timeToSend; i++) {
        setTimeout(function () {
          console.log("Tempo para enviar - " + that.timeToSend);
          if (that.timeToSend > 0) {
            that.message = "Próxima mensagem em " + that.timeToSend + " segundos";
            that.timeToSend -= 1;
          } else {
            //ENVIANDO MENSAGEM
            if (that.index < that.contatos.length) {
              console.log("Enviando agora");
              console.log(that.index);
              console.log(that.contatos[that.index]);
              that.send(that.contatos[that.index].phone, that.envio.mensagem, () => {
                that.logs.push("Mensagem enviada de " + that.container_selecionado.nome + " para " + that.contatos[that.index].phone);

                //CASO NÃO TENHA CHEGADO AO FIM DO ARRAY DE CONEXOES EMBARALHADAS
                if (that.indice_conexao_atual + 1 < that.conexoes_embaralhadas.length) {
                  //PASSANDO PARA A PROXIMA CONEXAO
                  that.indice_conexao_atual++;
                  that.container_selecionado = that.conexoes_embaralhadas[that.indice_conexao_atual];

                  if (that.index < that.contatos.length) {
                    that.sendMessage();
                  }
                } else {
                  //CASO TENHA CHEGADO AO FIM DO ARRAY DE CONEXOES EMBARALHADAS
                  that.preparaCombinacao();
                }

                that.posEnvio();
                that.index += 1;
              });
            } else {
              that.posEnvio();
            }
          }
        }, i * 1000);
      }
    },

    send(number, text, callback) {
      this.message = "Validando número...";

      //Validando número antes do envio
      this.validarNumero(number, this.container_selecionado.chave_api, this.container_selecionado.porta, (validNumber, timeout) => {
        //FEEDBACK
        this.message = "Enviando...";

        if (validNumber) {
          let onlyNumber = validNumber.split("@")[0];

          //OLD PRE ENVIO
          //this.preEnvio(onlyNumber, number.split("@")[0], text);

          this.checkFunil(onlyNumber);

          if (text) {
            //VERIFICANDO SE TEM ALGUMA TAG NO TEXTO PARA SER SUBSTITUIDA POR VALORES NO JSON
            text = this.formatarMensagem(text, number);
          }

          //ADICIONANDO RANDOM DE PONTOS PARA DIFERENCIAR MENSAGENS
          /*let points = Math.floor(Math.random() * 30) + 1;

            //ADICIONANDO ESPAÇO ANTES DOS PONTOS
            text = text + "\n";

            for (let i = 0; i < points; i++) {
              text = text + ".";
            }
            */

          console.log("Mensagem:");
          console.log(text);

          if (this.tipo_envio.includes("botoes")) {
            text = this.envio.meta_dados;
          }

          this.enviar(
            this.tipo_envio,
            validNumber,
            text,
            this.envio.url,
            this.container_selecionado.chave_api,
            this.container_selecionado.porta,
            (timeout, phone, sucess) => {
              this.getResult(timeout, phone, sucess, text, callback);
            }
          );
        } else {
          if (timeout) {
            this.message = "Falha ao validar número, conexão instável...";

            //REDUZINDO INDICE PARA TENTAR NOVAMENTE VALIDAR O MESMO NÚMERO
            if (this.index > 0) {
              this.index -= 1;
            }

            //AGUARDANDO 2 SEGUNDOS ANTES DE CHAMAR CALLBACK
            //SOMENTE PARA DAR TEMPO LER A MENSAGEM

            let that = this;

            setTimeout(function () {
              that.getResult(true, false, false, "", callback);
            }, 2000);
          } else {
            this.message = "WhatsApp inválido...";

            //REGISTRANDO MENSAGEM COM STATUS DE NÚMERO INVÁLIDO
            //TABELA new_mensagens
            this.criarMensagemDBStatus(number.split("@")[0], text, this.envio.id, this.container_selecionado.id, "NÚMERO INVÁLIDO");

            //ATUALIZANDO TOTAL DE NÚMEROS INVÁLIDOS NO ENVIO
            this.atualizarTotalInvalidos(this.envio.id);

            this.contInvalidNumbers += 1;

            this.envio.total_invalidos += 1;
            this.envio.calculo_invalidos = Number(this.envio.calculo_invalidos) + 1;
            //this.envio.calculo_total = Number(this.envio.calculo_total) + 1;;

            //REGISTRANDO MENSAGEM COM STATUS DE NÚMERO INVÁLIDO
            //TABELA numeros_envios
            this.atualizarStatusNumeroDB(this.envio.id, number.split("@")[0], "NÚMERO INVÁLIDO", number.split("@")[0]);

            //FINALIZANDO ENVIO
            if (this.envio.calculo_enviados + this.envio.calculo_invalidos == this.envio.calculo_total) {
              this.message = "Envio concluído";

              this.envio_concluido = true;

              window.location.reload();
            }

            //AGUARDANDO 2 SEGUNDOS ANTES DE CHAMAR CALLBACK
            //SOMENTE PARA DAR TEMPO LER A MENSAGEM
            setTimeout(function () {
              callback();
            }, 2000);
          }
        }
      });
    },

    inserirLogErro(log) {
      this.logsErro.push({
        id: this.cont_logs_erro,
        log: log,
        created_at: new Date(),
      });

      this.cont_logs_erro++;

      this.logsErro.sort(this.compare);

      if (this.logsErro.length >= 10) {
        this.logs.pop();
      }
    },

    compare(a, b) {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    },

    getResult(timeout, phone, sucess, text, callback) {
      if (timeout) {
        this.inserirLogErro("Falha no envio... Verifique a conexão de <b>" + this.container_selecionado.nome + "</b>");
        this.inserirLogErro("Refazendo sorteio");

        this.showErrorMessage("Falha no envio... Verifique a conexão de <b>" + this.container_selecionado.nome + "</b>");

        let indice = this.findInSelectedContainers(this.container_selecionado);

        if (indice >= 0) {
          this.conexoes_selecionadas.splice(indice, 1);
        }

        //this.preparaCombinacao();
        this.refazerSorteio();
      } else {
        //Verificando se foi enviado com sucesso
        console.log("Checking status...");

        if (sucess) {
          console.log("Success");
          //Prosseguir envio
          if (phone) {
            console.log("GET RESULT");
            console.log("Enviados " + this.envio.calculo_enviados);
            console.log("Invalidos " + this.envio.calculo_invalidos);
            console.log("Total " + this.envio.calculo_total);

            if (Number(this.envio.calculo_enviados) + Number(this.envio.calculo_invalidos) == Number(this.envio.calculo_total)) {
              this.message = "Envio concluído";

              window.location.reload();
            }
          }

          //PARÂMETROS
          //PRIMEIRO, O NÚMERO VALIDADO
          //SEGUNDO, O NÚMERO COMO FOI FORNECIDO NA CRIAÇÃO DO ENVIO
          this.preEnvio(phone, this.contatos[this.index].phone.split("@")[0], text, () => {
            console.log("Callback no pre");
            callback();
          });
        } else {
          this.inserirLogErro("Falha no envio... Verifique a conexão de <b>" + this.container_selecionado.nome + "</b>");
          this.inserirLogErro("Refazendo sorteio");

          this.showErrorMessage("Falha no envio... Verifique a conexão de <b>" + this.container_selecionado.nome + "</b>");

          let indice = this.findInSelectedContainers(this.container_selecionado);

          if (indice >= 0) {
            this.conexoes_selecionadas.splice(indice, 1);
          }

          //this.preparaCombinacao();
          this.refazerSorteio();
        }
      }
    },

    preEnvio(formatedPhone, phone, text, callback) {
      if (phone) {
        this.contEnviados += 1;
        this.envio.total_enviados += 1;
        this.total_enviados += 1;
        this.envio.calculo_enviados = Number(this.envio.calculo_enviados) + 1;
        //this.envio.calculo_total = Number(this.envio.calculo_total) + 1;;

        console.log("Container no pre");
        console.log(this.container_selecionado);

        console.log("Contatos");
        console.log(this.contatos);

        console.log("Indice");
        console.log(this.index);

        this.criarMensagemDB(formatedPhone, text, this.envio.id, this.container_selecionado.id);
        this.atualizarTotalEnviados(this.envio.id);
        this.atualizarStatusNumeroDBContainer(
          this.envio.id,
          phone,
          "ENVIADO",
          formatedPhone,
          this.contatos[this.index].id,
          this.container_selecionado.id
        );

        if (callback) {
          callback();
        }
      }
    },

    posEnvio() {
      console.log("POS ENVIO");
      console.log("Enviados " + this.envio.calculo_enviados);
      console.log("Invalidos " + this.envio.calculo_invalidos);
      console.log("Total " + this.envio.calculo_total);

      if (Number(this.envio.calculo_enviados) + Number(this.envio.calculo_invalidos) == Number(this.envio.calculo_total)) {
        this.message = "Envio concluído";

        this.envio_concluido = true;

        this.envio.status_do_envio = "CONCLUIDO";

        window.location.reload();
      }
    },

    checkFunil(phone) {
      console.log("\n\n\nVerificando funil");
      console.log(this.container.url);

      //CÓDIGO OFICIAL DO FUNIL
      if (this.funil) {
        console.log("ADICIONANDO AO FUNIL");

        this.addToFunil(phone, this.funil.id);
        //this.sendToWebhook(this.container.id, phone, this.funil.id, this.apiId, this.usuario.porta);
      } else {
        console.log("FUNIL EM BRANCO");
      }
    },

    addToFunil(phone, idFunil) {
      console.log("\n\nADICIONANDO AO FUNIL");

      console.log(this.container_selecionado);

      console.log(
        process.env.MIX_VUE_APP_ENDPOINT +
          this.container_selecionado.porta +
          `/funil/add/` +
          this.container_selecionado.id +
          `/` +
          phone +
          `/` +
          idFunil +
          "/" +
          this.container_selecionado.chave_api
      );

      axios
        .get(
          process.env.MIX_VUE_APP_ENDPOINT +
            this.container_selecionado.porta +
            `/funil/add/` +
            this.container_selecionado.id +
            `/` +
            phone +
            `/` +
            idFunil +
            "/" +
            this.container_selecionado.chave_api
        )
        .then((response) => {
          console.log("\n\n\n\n\nRetorno da api FUNIL");
          console.log(response.data);
        })
        .catch((error) => {
          console.log("\n\n\n\n\nERRO da api FUNIL");
          console.log(error);
        });
    },

    getTime() {
      let time = Math.floor(Math.random() * (this.maxTime - this.minTime + 1)) + this.minTime;
      //let time = Math.floor(Math.random() * (30 - 1 + 1)) + 20;

      //let time = Math.floor(Math.random() * (5 - 1 + 1)) + 2;

      //this.message = "Próxima mensagem em " + time + " segundos";

      console.log("Aguardando " + time + " segundos...");

      return time;
    },

    formatarMensagem(mensagem, numero) {
      //VERIFICANDO SE O ENVIO POSSUI METADADADOS
      //O ENVIO SÓ POSSUI METADADOS CASO TENHA SIDO ENVIADO UM JSON
      if (this.envio.meta_dados) {
        //BUSCANDO TAGS ADICIONADAS NA MENSAGEM
        let arrayTags = this.localizar_tags(this.envio.mensagem);

        console.log("TAGS ENCONTRADAS");
        console.log(arrayTags);

        //VERIFICANDO SE O TEXTO POSSUI ALGUMA TAG
        if (arrayTags && arrayTags.length > 0) {
          let matchJSON = this.buscarNumeroNoJSON(numero);

          //CASO ENCONTRE OS DADOS NO JSON
          if (matchJSON) {
            let mensagemFinal = this.envio.mensagem;

            for (var prop in matchJSON) {
              //VERIFICANDO SE O TEXTO CONTEM UMA TAG COM A CHAVE DO JSON
              if (this.envio.mensagem.includes("<" + prop + ">")) {
                console.log("ENTROU NO IF");
                mensagemFinal = this.replaceAll(mensagemFinal, "<" + prop + ">", matchJSON[prop]);
              }
            }

            let tagsAdicionais = this.localizar_tags(mensagemFinal);

            if (tagsAdicionais && tagsAdicionais.length > 0) {
              for (var prop2 in matchJSON) {
                //VERIFICANDO SE O TEXTO CONTEM UMA TAG COM A CHAVE DO JSON
                if (mensagemFinal.includes("<" + prop2 + ">")) {
                  console.log("ENTROU NO IF");
                  mensagemFinal = this.replaceAll(mensagemFinal, "<" + prop2 + ">", matchJSON[prop2]);
                }
              }
            }

            return mensagemFinal;
          }
        }
      }

      return mensagem;
    },

    buscarNumeroNoJSON(numero) {
      console.log("BUSCANDO MATCH PARA " + numero);

      let json = JSON.parse(JSON.stringify(this.json_envio));

      console.log(this.json_envio);
      console.log(json);

      if (numero.length >= 8) {
        for (let i = 0; i < json.length; i++) {
          let telefone = "";

          if (json[i].Telefone) {
            telefone = json[i].Telefone;
          } else {
            telefone = json[i].telefone;
          }

          if (telefone) {
            telefone = telefone.replace(/[^Z0-9]/g, "");
            telefone = telefone.slice(telefone.length - 8);

            //VERIFICANDO COM 'INCLUDES' POIS O CÓDIGO ADICIONA O 55 NO NUMERO E NÃO NO JSON
            console.log(json[i]);
            console.log("CHECANDO TRUE - " + json[i].enviado + " Possui ? " + !json[i].enviado);
            console.log("COMPARANDO " + telefone);

            if (numero.includes(telefone) && telefone.length >= 8 && (!json[i].enviado || json[i].enviado != true)) {
              console.log("ACHOU");
              json[i].enviado = true;
              this.json_envio[i].enviado = true;

              for (let j = 0; j < json.length; j++) {
                for (var prop in json[j]) {
                  if (typeof json[j][prop] == "string") {
                    json[j][prop] = this.replaceAll(json[j][prop], "\\n", "\n");
                    json[j][prop] = this.replaceAll(json[j][prop], "\n", "\\n");
                  }
                }
              }

              this.salvarJSONNoEnvio(JSON.stringify(json), this.envio.id);

              return this.json_envio[i];
            }
          } else {
            console.log("Campo telefone não encontrado");
          }
        }
      } else {
        console.log("Número inválido na validação do JSON");
      }
    },

    salvarJSONNoEnvio(json, id_envio) {
      console.log("ATUALIZANDO JSON");

      let data = {
        json: json,
        id_envio: id_envio,
      };

      console.log(data);

      axios
        .post("/send/update_json", data)
        .then((response) => {
          if (response.data) {
            console.log("JSON ATUALIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    localizar_tags(texto) {
      var emails = new Array();
      let i = 0;

      while (texto.search("<") != -1) {
        let pos_inicio = texto.search("<");
        let pos_fim = texto.search(">");

        let email = texto.substring(pos_inicio + 1, pos_fim);
        emails[i] = email;

        texto = texto.substring(pos_fim + 1, texto.length);
        i++;
      }

      return emails;
    },

    checkConnectedNumber(connection) {
      if (!connection.numero_conectado || connection.numero_conectado == "") {
        let indice = this.findInSelectedContainers(connection);

        if (indice >= 0) {
          this.conexoes_selecionadas.splice(indice, 1);
        }

        this.showErrorMessageWithButton("Ops...", "Esta conexão não possui telefone definido.");
      }
    },

    validateTime() {
      if (this.minTime > this.maxTime) {
        this.maxTime = this.minTime;
      }

      if (this.minTime < 0) {
        this.minTime = 0;
      }

      if (this.minTimeResposta > this.maxTimeResposta) {
        this.maxTimeResposta = this.minTimeResposta;
      }

      if (this.minTimeResposta < 0) {
        this.minTimeResposta = 0;
      }
    },

    selectAll() {
      if (!this.allSelected) {
        for (let i = 0; i < this.auxContainers.length; i++) {
          if (!this.conexoes_selecionadas.includes(this.auxContainers[i])) {
            this.conexoes_selecionadas.push(this.auxContainers[i]);
          }
        }

        this.allSelected = true;
      } else {
        for (let i = 0; i < this.auxContainers.length; i++) {
          let indice = this.findInSelectedContainers(this.auxContainers[i]);

          if (indice >= 0) {
            this.conexoes_selecionadas.splice(indice, 1);
          }
        }

        this.allSelected = false;
      }
    },

    findInSelectedContainers(id) {
      let indice = this.conexoes_selecionadas.findIndex((element) => element == id);

      return indice;
    },

    refazerSorteio() {
      //MÉTODO CHAMADO AO DAR ERRO DURANTE O ENVIO
      this.conexoes_embaralhadas = this.conexoes_selecionadas;

      console.log("Tamanho conexões " + this.conexoes_embaralhadas.length);
      console.log("Indice atual " + this.indice_conexao_atual);

      console.log("Array atual");
      console.log(this.conexoes_embaralhadas);

      this.container_selecionado = this.conexoes_embaralhadas[this.indice_conexao_atual];

      if (this.indice_conexao_atual >= this.conexoes_embaralhadas.length) {
        this.preparaCombinacao();
      } else {
        this.sendMessage();
      }
    },

    preparaCombinacao() {
      this.loop = this.loop + 1;
      this.parcial = 0;

      if (this.conexoes_selecionadas.length >= 1) {
        //EMBARALHANDO CONEXÕES
        //this.conexoes_embaralhadas = this.embaralharArray(this.conexoes_selecionadas);

        //ENVIANDO NA ORDEM
        this.conexoes_embaralhadas = this.conexoes_selecionadas;

        console.log("Conexoes");
        console.log(this.conexoes_embaralhadas);

        for (let i = 0; i < this.conexoes_embaralhadas.length; i++) {
          console.log("Conexão " + i + " = " + this.conexoes_embaralhadas[i].nome);
        }

        //INICIALIZANDO VARIAVEIS DO ENVIO
        this.indice_conexao_atual = 0;
        this.container_selecionado = this.conexoes_embaralhadas[this.indice_conexao_atual];

        this.sendMessage();

        //SIMULAR ENVIO
        //this.simularSendMessage();
      } else {
        this.showErrorMessageWithButton("Ops...", "Nenhuma conexão disponível.");
        this.message = "Nenhuma conexão disponível.";
        this.continuar_envio = true;
      }
    },

    simularSendMessage() {
      //CASO NÃO TENHA CHEGADO AO FIM DO ARRAY DE CONEXOES EMBARALHADAS
      if (this.indice_conexao_atual + 1 <= this.conexoes_embaralhadas.length) {
        console.log("Enviando agora de ");
        console.log(this.conexoes_embaralhadas[this.indice_conexao_atual].nome);

        //PASSANDO PARA A PROXIMA CONEXAO
        this.indice_conexao_atual++;
        this.container_selecionado = this.conexoes_embaralhadas[this.indice_conexao_atual];

        if (this.index < this.contatos.length) {
          this.simularSendMessage();
        }
      } else {
        //CASO TENHA CHEGADO AO FIM DO ARRAY DE CONEXOES EMBARALHADAS
        if (this.qtd_loops_simulacao > 0) {
          console.log("Próximo loop\n\n");
          this.qtd_loops_simulacao--;
          this.preparaCombinacao();
        } else {
          console.log("Fim simulação");
        }
      }

      this.index += 1;
    },

    compare(a, b) {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    },

    findCorrelacao(id) {
      console.log("ID ATUAL");
      console.log(id);
      let indice = this.auxContainers.findIndex((element) => element["id"] == id);

      return indice;
    },

    embaralharArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        // Troca os elementos nas posições i e j
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    getTime() {
      let time = Math.floor(Math.random() * (this.maxTime - this.minTime + 1)) + this.minTime;

      return time;
    },

    getTimeResposta() {
      let time = Math.floor(Math.random() * (this.maxTimeResposta - this.minTimeResposta + 1)) + this.minTimeResposta;

      return time;
    },

    redirect(page) {
      window.location.href = page;
    },

    findFunil(id) {
      let indice = this.funis.findIndex((element) => element["id"] == id);

      return indice;
    },

    findMensagemPadrao(id) {
      let indice = this.mensagens_padrao.findIndex((element) => element["id"] == id);

      return indice;
    },

    validateFields() {
      this.message = "";
      this.messageRespostaTempo = "";
      this.messageResposta = "";
      this.messageRemetente = "";

      if (this.conexoes_selecionadas == 0) {
        this.showErrorMessageWithButton("Ops...", "selecione as conexões para o envio!");
      } else {
        this.preparaCombinacao();
      }
    },
  },
};
</script>

<style scoped>
.fire {
  font-size: 15px;
  background: rgb(254, 181, 1);
  background: linear-gradient(90deg, rgba(254, 181, 1, 1) 29%, rgba(255, 211, 2, 1) 46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.enviando {
  background: #22ca80 !important;
  color: white !important;
}

.recebendo {
  background: rgb(52, 52, 223) !important;
  color: white !important;
}

.acompanhamento {
  margin-left: 30px;
  margin: 10px;
  padding: 10px;
  width: 100%;
}

.loop {
  color: red;
}

.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr:nth-child(even) {
  background: #fff;
}

table tr:nth-child(odd) {
  background: #eee;
}

table.pdi tr:nth-child(even) {
  background: #fff;
}

table.pdi tr:nth-child(odd) {
  background: #80f0af;
}

table caption {
  font-size: 1.2em;
  margin: 0.1em 0 0.5em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.1em;
}

table th,
table td {
  padding: 0.2em;
  text-align: center;
  font-size: 0.9em;
}

table th {
  font-size: 0.7em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    padding: 0.4em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.9em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.cursor {
  cursor: pointer;
}
</style>
