<template>
  <br />
  <!-- FILTROS -->
  <div class="card">
    <div class="card-header">
      <h5 class="card-title" id="accordion">Configurações</h5>
    </div>
    <!--<div id="collapse1" class="collapse" data-parent="#accordion"> -->
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text my-group-text" style="font-weight: bold">Tempo mín. envio</span>
              </div>
              <input class="form-control" id="descricao" @keyup="validateTime" v-model="minTime" type="number" style="text-transform: uppercase" />
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text my-group-text" style="font-weight: bold">Tempo max. envio</span>
              </div>
              <input class="form-control" id="descricao" @keyup="validateTime" v-model="maxTime" type="number" style="text-transform: uppercase" />
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <button @click="enviarNoGrupo()" class="btn btn-success">
              Iniciar aquecimento
              <i style="margin-left: 5px" class="fa-solid fa-fire fire"></i>
            </button>
          </div>
        </div>
      </div>
      <!--  </div> -->
    </div>
  </div>

  <!-- CONEXÕES -->
  <div class="col-md-12">
    <div class="card card-primary">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <label for="exampleSelectRounded0">Selecione as conexões para aquecimento</label>

            <table>
              <thead>
                <tr>
                  <th style="width: 5%"><input v-model="allSelected" type="checkbox" v-on:click="selectAll()" /></th>
                  <th scope="col" style="width: 30%">conexões</th>
                  <th scope="col">ID</th>
                  <th scope="col">Tags</th>
                  <th scope="col">Status</th>
                  <th scope="col">Enviadas</th>
                  <!-- <th scope="col" style="width: 30%">Conexões</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="container in auxContainers"
                  :key="container"
                  :value="container"
                  :class="{ enviando: remetente.id == container.id, recebendo: destinatario.id == container.id }"
                >
                  <td data-label="Região">
                    <input
                      @change="checkConnectedNumber(container)"
                      type="checkbox"
                      :id="container.id"
                      :value="container"
                      v-model="conexoes_selecionadas"
                    />
                  </td>
                  <td data-label="Nome">
                    {{ container.nome }}
                  </td>
                  <td data-label="Nome">
                    {{ container.id }}
                  </td>
                  <td data-label="Tags">
                    <span v-for="(tag, index) in container.tags" :style="'margin-right:5px;color: white;background-color:' + tag.cor" class="badge">
                      {{ tag.tag }}
                    </span>
                  </td>
                  <td data-label="Status">
                    <connection-status :my_container="container" :usuario="container.usuario" />
                  </td>
                  <td data-label="Enviado">
                    {{ container.mensagens_enviadas }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- AVISOS E MENSAGENS -->
            <br />
            <h4 style="font-size: 15px">Loop: {{ loop }}</h4>
            <h4 style="font-size: 15px">Passo: {{ parcial }} de {{ totalMatriz }}</h4>
            <div style="font-size: 15px" v-html="messageRemetente"></div>
            <h4 style="font-size: 15px; font-weight: bold">{{ message }}</h4>
            <br />
            <div v-if="messageResposta" style="font-size: 15px" v-html="messageResposta"></div>
            <h4 v-if="messageRespostaTempo" style="font-size: 15px; font-weight: bold">{{ messageRespostaTempo }}</h4>

            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- LOGS DE ERRO -->
    <div class="card card-primary" v-if="logsErro && logsErro.length">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label for="exampleSelectRounded0">Logs de erro</label>
            <table>
              <thead>
                <tr>
                  <th scope="col" style="width: 10%">Logs</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in logsErro" :key="logs" :value="logs">
                  <td data-label="Logs">
                    <div v-html="log"></div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- LOGS DE ATIVIDADE -->
    <div class="card card-primary" v-if="logs && logs.length">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label for="exampleSelectRounded0">Logs de atividade</label>
            <table>
              <thead>
                <tr>
                  <th scope="col" style="width: 10%">Remetente</th>
                  <th scope="col" style="width: 10%">Destinatário</th>
                  <th scope="col" style="width: 30%">Mensagem</th>
                  <th scope="col" style="width: 30%">Resposta</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="logs in logs" :key="logs" :value="logs">
                  <td data-label="Remetente">
                    {{ logs.remetente }}
                  </td>
                  <td data-label="Destinatário">
                    {{ logs.destinatario }}
                  </td>
                  <td data-label="Mensagem">
                    {{ logs.mensagem }}
                  </td>
                  <td data-label="Resposta">
                    {{ logs.resposta }}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import sendController from "../controller/sendController";

export default {
  props: [
    "my_container",
    "blacklists",
    "containers",
    "usuario",
    "funis",
    "mensagens_padrao",
    "contatos_para_envio",
    "id_mensagem_padrao",
    "aux_list_break",
    "nao_redirecionar",
    "aux_meta_dados",
    "editar",
    "cancelar",
  ],

  mixins: [wppController, sweetAlert, Swal, sendController],

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      url_to_send: null,
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      funil_selecionado: "",
      tipo_envio: "",
      mensagem_selecionada: "",
      envio_para_grupos: 0,
      blacklist_selecionada: "",
      contador_blacklist: 0,
      dont_remove_duplicated: false,
      conexoes_selecionadas: [],
      resultadoParAPar: [],
      resultadoEmbaralhado: [],
      remetente: [],
      destinatario: [],
      message: "",
      messageRemetente: "",
      messageResposta: "",
      minTime: 3,
      maxTime: 5,
      loop: 0,
      parcial: 0,
      totalMatriz: 0,
      auxContainers: [],
      qtdenviado: [],
      qtdrecebido: [],
      arrayContainers: [],
      logs: [],
      logsErro: [],
      editar_script_conversa: false,
      script_conversa: "",
      conversa: "",
      cont_logs: 0,
      cont_logs_erro: 0,
      timeToSendResposta: 0,
      messageRespostaTempo: 0,
      maxTimeResposta: 5,
      minTimeResposta: 3,
      allSelected: false,
      qtd_loops_simulacao: 2,
      grupos: "",
      array_grupos: [],
      array_mensagens: [],
      indice_conexao_atual: 0,
      array_textos: [],
      array_audios: [],
      array_tipos_status: ["texto", "imagem", "video"],
      array_status_texto: [],
      array_status_texto_cor: [],
      array_status_imagem: [],
      array_status_video: [],
    };
  },

  mounted() {
    //this.validarUsuario();

    if (this.containers) {
      this.container = this.containers[0];
    } else {
      this.container = this.my_container;
    }
    this.auxContainers = this.containers;

    for (let i = 0; i < this.auxContainers.length; i++) {
      console.log("entrei no laço");
      this.auxContainers[i].enviados = 0;
      this.auxContainers[i].recebidos = 0;
      this.auxContainers[i].usuario = { porta: this.auxContainers[i].user_porta };      
      this.auxContainers[i].tags = JSON.parse(this.auxContainers[i].tags);
    }

    console.log("Con 6");
    console.log(this.auxContainers[5]);

    this.script_conversa = this.usuario.conversas_aquecimento;
    this.conversa = this.script_conversa.split(/\r?\n/);

    //STATUS
    this.array_status_texto.push("Preencha o seu coração com belas palavras de sabedoria todos os dias. 💌");
    this.array_status_texto.push("Nenhum ser humano conseguiu voar sem primeiro tirar os pés do chão. 😉");
    this.array_status_texto.push("Transformando os meus erros em sementes. 🌱");
    this.array_status_texto.push("Quando chover, busque pelo arco-íris. Quando escurecer, busque pelas estrelas. 🌈");
    this.array_status_texto.push("As dificuldades existem para separar o belo do extraordinário.");
    this.array_status_texto.push("Abrir a janela e agradecer: esse é o meu lema do dia. 🥰");
    this.array_status_texto.push("Vamos combinar de ser feliz? 😜");
    this.array_status_texto.push("Ela é luz. Por onde passa, contagia o ambiente de alegria. 🌹");
    this.array_status_texto.push("No fim, tudo acaba bem. E se não for acabar, a gente constrói um novo final.");
    this.array_status_texto.push("A vida pode ser simples. É só saber apreciar as coisas certas.");
    this.array_status_texto.push("Quando tudo for pedra, atire a primeira flor! 🌼 🌺");
    this.array_status_texto.push("Não espere pelo momento perfeito. Faça de cada momento a oportunidade perfeita.");
    this.array_status_texto.push("Há coisas lindas esperando por você hoje. 😜");
    this.array_status_texto.push("A beleza de ser você não tem preço. 💌");
    this.array_status_texto.push("Sonho não se discute, se conquista!");

    //COR DO STATUS TEXTO
    this.array_status_texto_cor.push("#d80230");
    this.array_status_texto_cor.push("#d8024d");
    this.array_status_texto_cor.push("#5b02d8");
    this.array_status_texto_cor.push("#02d8d8");
    this.array_status_texto_cor.push("#97dede");
    this.array_status_texto_cor.push("#d197de");
    this.array_status_texto_cor.push("#733054");
    this.array_status_texto_cor.push("#733030");

    //STATUS IMAGEM
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282792/Status%20whatsapp/mtfme3mf4vlkaxoi8bwr.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282792/Status%20whatsapp/knwv3pdk9cxoha6ldjbg.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282792/Status%20whatsapp/wohufk4vtmazqjalyz8l.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282792/Status%20whatsapp/u2a5kjtlg8ht130lyitd.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282792/Status%20whatsapp/dcc3cmgo5ixyxcopd9pj.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282791/Status%20whatsapp/p8rzzrvkkqvuktzble0s.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282792/Status%20whatsapp/qq0lbghsymgafhmitkgz.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282791/Status%20whatsapp/ztixj44vlsl8drvbaqgj.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282791/Status%20whatsapp/grawnihty2lhubygjhsa.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282791/Status%20whatsapp/fhil0gtoox1vniabxn3y.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282791/Status%20whatsapp/pimdctkibvwkeaozic4z.jpg");
    this.array_status_imagem.push("https://res.cloudinary.com/djyh2nsyi/image/upload/v1729282791/Status%20whatsapp/ntlh9cx0q5icwsr47ac2.jpg");

    //STATUS VIDEO
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283637/Status%20whatsapp/Videos/h2dwudzm6vxfdg9aehko.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283636/Status%20whatsapp/Videos/sp65kc0vzrvjjt6d0svy.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283635/Status%20whatsapp/Videos/un4rimu2jfqfrr1sgtfj.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283634/Status%20whatsapp/Videos/tjsyk6irahmabhaq9zbr.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283634/Status%20whatsapp/Videos/uawtwssrp3g17ztptj5j.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283634/Status%20whatsapp/Videos/f0gzcfxelq5u7ub8scyy.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283634/Status%20whatsapp/Videos/unkqmruoi4bnveediihx.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283634/Status%20whatsapp/Videos/qm6xjtfyzhvgkyfqasqy.mp4");
    this.array_status_video.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729283634/Status%20whatsapp/Videos/qkcjwczrli9p0tjjznhu.mp4");

    //MENSAGENS NOS GRUPOS
    this.array_audios.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729252515/ziz02usgiemgdizonii0.mp3");
    this.array_audios.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729252515/iin8fo37v0ls8bop9sp5.mp3");
    this.array_audios.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729252515/mwarsf6qvly9njc2kvzr.mp3");
    this.array_audios.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729252515/oksjayrzww91h6gvwkai.mp3");
    this.array_audios.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729252515/ofunnnjfd5ig6xo4jnqg.mp3");
    this.array_audios.push("https://res.cloudinary.com/djyh2nsyi/video/upload/v1729252515/kulrq7lwymx2s1tdy6lc.mp3");

    for (let i = 0; i < this.array_audios.length; i++) {
      this.array_mensagens.push({
        tipo_envio: "audio",
        url: this.array_audios[i],
      });
    }

    this.array_textos.push("Oi, você já conferiu as opções de planos de saúde disponíveis para você?");
    this.array_textos.push("É importante revisar sua cobertura de saúde regularmente.");
    this.array_textos.push("Lembre-se de verificar a rede de médicos e hospitais do seu plano.");
    this.array_textos.push("Você sabe quais são as carências do seu plano de saúde?");
    this.array_textos.push("Caso precise, entre em contato com a central de atendimento do seu plano.");
    this.array_textos.push("Fique atento às datas de renovação e pagamento da mensalidade.");
    this.array_textos.push("Você conhece os procedimentos para solicitar reembolso?");
    this.array_textos.push("Sempre guarde cópias de exames e consultas para facilitar o acompanhamento.");
    this.array_textos.push("É fundamental manter seus dados atualizados junto à operadora.");
    this.array_textos.push("Não hesite em fazer perguntas sobre a cobertura do seu plano!");

    this.array_textos.push("Oi, como você está?");
    this.array_textos.push("Estou bem, e você?");
    this.array_textos.push("Você assistiu ao último episódio daquela série?");
    this.array_textos.push("Ainda não, mas estou ansioso para ver!");
    this.array_textos.push("Qual foi o seu fim de semana?");
    this.array_textos.push("Foi ótimo! Fui à praia e descansei bastante.");
    this.array_textos.push("Tem algum lugar novo que você recomenda para comer?");
    this.array_textos.push("Sim! Tem um restaurante tailandês incrível que abriu aqui perto.");
    this.array_textos.push("Você viu o que aconteceu nas notícias hoje?");
    this.array_textos.push("Vi sim, é impressionante como as coisas mudam rapidamente.");
    this.array_textos.push("Qual é o seu livro favorito?");
    this.array_textos.push("Acho que é 'Cem Anos de Solidão'. E o seu?");
    this.array_textos.push("Vamos marcar um café qualquer dia desses?");
    this.array_textos.push("Claro! Que tal no próximo sábado?");
    this.array_textos.push("Você já planejou suas férias?");
    this.array_textos.push("Estou pensando em ir para a praia. E você?");
    this.array_textos.push("Como está o trabalho? Tudo tranquilo?");
    this.array_textos.push("Está tranquilo, mas bastante corrido. E o seu?");
    this.array_textos.push("O que você está ouvindo de música ultimamente?");
    this.array_textos.push("Estou ouvindo muito rock alternativo. E você?");
    this.array_textos.push("Você praticou algum esporte recentemente?");
    this.array_textos.push("Sim, comecei a correr. É desafiador, mas muito bom!");
    this.array_textos.push("Tem algum hobby novo que você começou?");
    this.array_textos.push("Estou aprendendo a tocar violão. É bem divertido!");
    this.array_textos.push("O que você acha da nova exposição no museu?");
    this.array_textos.push("Eu adorei! As obras são incríveis.");
    this.array_textos.push("Quais são suas metas para este mês?");
    this.array_textos.push("Quero me exercitar mais e ler pelo menos dois livros.");
    this.array_textos.push("Você já assistiu a algum filme bom recentemente?");
    this.array_textos.push("Sim! Vi um filme de suspense que me prendeu do começo ao fim.");
    this.array_textos.push("Como foi seu dia?");
    this.array_textos.push("Foi tranquilo, consegui fazer tudo o que planejei.");
    this.array_textos.push("Tem alguma receita nova que você quer experimentar?");
    this.array_textos.push("Sim! Estou querendo fazer um curry vegetariano.");
    this.array_textos.push("O que você faria se ganhasse na loteria?");
    this.array_textos.push("Investiria e viajaria pelo mundo!");
    this.array_textos.push("Qual é o seu lugar favorito na cidade?");
    this.array_textos.push("Acho que o parque central, é perfeito para relaxar.");
    this.array_textos.push("Você já foi a algum evento legal ultimamente?");
    this.array_textos.push("Fui a um show incrível na semana passada.");

    for (let i = 0; i < this.array_textos.length; i++) {
      this.array_mensagens.push({
        tipo_envio: "texto",
        texto: this.array_textos[i],
      });
    }

    this.array_mensagens.push({
      tipo_envio: "gif",
      texto: "Topp!!",
      url: "https://res.cloudinary.com/dajud3ltn/video/upload/v1729218017/session-9/uic5ii3n1aaehuwklkk6.mp4",
    });

    this.array_mensagens.push({
      tipo_envio: "gif",
      texto: "Podemos usar essa imagem pra campanha.",
      url: "https://res.cloudinary.com/dajud3ltn/video/upload/v1729218051/session-9/mnsej2dkd0iuygl8k3pa.mp4",
    });

    this.array_mensagens.push({
      tipo_envio: "texto",
      texto:
        "Então, talvez um plano que ofereça uma boa rede de médicos e cobertura para exames seja ideal. Você já verificou se os profissionais que você gosta estão credenciados?",
    });

    this.array_mensagens.push({
      tipo_envio: "texto",
      texto: "Você já pensou em fazer um plano de saúde?",
    });

    this.array_mensagens.push({
      tipo_envio: "texto",
      texto: "Boa ideia. E o preço? É muito mais caro do que um convênio popular?",
    });

    this.array_mensagens.push({
      tipo_envio: "texto",
      texto:
        "Exato. Cada plano tem suas regras. Fique de olho, especialmente para procedimentos mais complexos. Às vezes, a carência é maior para cirurgias ou tratamentos específicos.",
    });

    this.array_mensagens.push({
      tipo_envio: "texto",
      texto: "Olá, boa noite!",
    });

    this.array_mensagens.push({
      tipo_envio: "imagem",
      texto: "Podemos usar essa imagem pra campanha.",
      url: "https://res.cloudinary.com/djyh2nsyi/image/upload/v1729013261/WhatsApp_Image_2024-10-14_at_19.45.52_fnxljv.jpg",
    });

    this.array_mensagens.push({
      tipo_envio: "audio",
      url: "https://res.cloudinary.com/dajud3ltn/video/upload/v1729213780/session-9/uuqpdhhbr7kca5aukiaa.mp3",
    });

    this.array_mensagens.push({
      tipo_envio: "audio",
      url: "https://res.cloudinary.com/dajud3ltn/video/upload/v1729214533/session-9/njoy7uwnndntziiumysq.mp3",
    });

    this.array_grupos.push({
      nome_grupo: "Aquecimento 1",
      codigo: "120363347749212370@g.us",
      link_convite: "https://chat.whatsapp.com/Ei42cHTV7LU4l99rIZMoDV",
    });

    this.array_grupos.push({
      nome_grupo: "Aquecimento 2",
      codigo: "120363331728591954@g.us",
      link_convite: "https://chat.whatsapp.com/G1oTLOVkaDG6NoXNMcXIg9",
    });

    this.array_grupos.push({
      nome_grupo: "Aquecimento 3",
      codigo: "120363330549921650@g.us",
      link_convite: "https://chat.whatsapp.com/InKVyFtSc91GAUeJT4dTK1",
    });

    this.array_grupos.push({
      nome_grupo: "Aquecimento 4",
      codigo: "120363350471826734@g.us",
      link_convite: "https://chat.whatsapp.com/I3J8utDNB8O8mBYn6EEHhV",
    });

    this.array_grupos.push({
      nome_grupo: "Aquecimento 5",
      codigo: "120363331492307611@g.us",
      link_convite: "https://chat.whatsapp.com/IUt0BbPoQjJHAnHgo4r1Yf",
    });
  },

  methods: {
    enviarNoGrupo(indice) {
      if (this.conexoes_selecionadas.length > 0) {
        if (indice != null) {
          console.log("Atribuindo novo indice " + indice);
          this.indice_conexao_atual = indice;
        } else {
          console.log("NÃO Atribuindo novo indice " + indice);
        }

        let conexao = this.conexoes_selecionadas[this.indice_conexao_atual];

        console.log("Conexão atual " + indice + " - " + this.indice_conexao_atual);
        console.log(conexao);

        //GRUPO QUE NÃO ESTOU INSERIDO
        //120363021808904234@g.us

        let indice_grupo = Math.floor(Math.random() * this.array_grupos.length);
        let grupo_sorteado = this.array_grupos[indice_grupo];

        console.log("Grupo sorteado");
        console.log(indice_grupo);
        console.log(grupo_sorteado);
        console.log(this.array_grupos);

        console.log(this.array_grupos);

        if (conexao) {
          //AÇÃO STATUS
          let postar_status = this.calcularTempoStatus(conexao.status_postado_em);
          console.log("Postar status?");
          console.log(postar_status);

          if (postar_status) {
            let tipo_status = "";

            //VERIFANDO SE JÁ POSTOU ALGO
            if (conexao.tipo_status_postado) {
              let new_array = this.array_tipos_status;

              //REMOVENDO ÚLTIMO TIPO STATUS POSTADO
              const index = new_array.indexOf(conexao.tipo_status_postado);
              if (index > -1) {
                new_array.splice(index, 1);
              }

              //SORTEANDO ENTRE OS QUE SOBRARAM
              let indice_tipo_status = Math.floor(Math.random() * new_array.length);

              tipo_status = new_array[indice_tipo_status];
            } else {
              //SORTEANDO TIPO STATUS
              let indice_tipo_status = Math.floor(Math.random() * this.array_tipos_status.length);

              tipo_status = this.array_tipos_status[indice_tipo_status];
            }

            console.log("Status sorteado = " + tipo_status);

            let texto = "";
            let cor = "";
            let url = "";

            //POSTANDO STATUS AGORA

            //SORTEANDO TEXTO E COR PARA POSTAR
            if (tipo_status == "texto") {
              texto = this.array_status_texto[Math.floor(Math.random() * this.array_status_texto.length)];
              cor = this.array_status_texto_cor[Math.floor(Math.random() * this.array_status_texto_cor.length)];
            }

            //SORTEANDO IMAGEM PARA POSTAR
            if (tipo_status == "imagem") {
              url = this.array_status_imagem[Math.floor(Math.random() * this.array_status_imagem.length)];
            }

            //SORTEANDO IMAGEM PARA POSTAR
            if (tipo_status == "video") {
              url = this.array_status_video[Math.floor(Math.random() * this.array_status_video.length)];
            }

            this.wait(() => {
              this.message = "Postando status agora (" + tipo_status + ")...";

              this.sendStatus(tipo_status, texto, cor, url, conexao.chave_api, conexao.porta, (response) => {
                if (response && response.status != "Error") {
                  //ATUALIZANDO STATUS POSTADO
                  var data_postagem_status = moment(
                    new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }),
                    "MM-DD-YYYY HH:mm:ss A"
                  ).format("yyyy-MM-DD HH:mm:ss");

                  let data = {
                    container_id: conexao.id,
                    status_postado_em: data_postagem_status,
                    tipo_status_postado: tipo_status,
                  };

                  axios
                    .post(`/aquecimento/atualizar_status`, data)
                    .then((response) => {
                      this.conexoes_selecionadas[this.indice_conexao_atual].tipo_status_postado = tipo_status;
                      this.conexoes_selecionadas[this.indice_conexao_atual].status_postado_em = data_postagem_status;

                      console.log(response);

                      if (this.indice_conexao_atual + 1 < this.conexoes_selecionadas.length) {
                        console.log("Entrou na condicao 01");
                        this.enviarNoGrupo(this.indice_conexao_atual + 1);
                      } else {
                        console.log("Entrou na condicao 02");
                        this.enviarNoGrupo(0);
                      }
                    })
                    .catch((error) => {
                      this.showErrorMessageWithButton("Ops..", error.response.data);
                      console.log(error.response.data);
                    });
                } else {
                  this.message = "Falha ao postar status...";

                  this.logsErro.push(
                    "Falha no envio... Verifique a conexão de <b>" + this.conexoes_selecionadas[this.indice_conexao_atual].nome + "</b>"
                  );
                  this.logsErro.push("Refazendo sorteio");

                  this.showErrorMessage(
                    "Falha no envio... Verifique a conexão de <b>" + this.conexoes_selecionadas[this.indice_conexao_atual].nome + "</b>"
                  );

                  let indice = this.findInSelectedContainers(this.conexoes_selecionadas[this.indice_conexao_atual]);

                  if (indice >= 0) {
                    this.conexoes_selecionadas.splice(indice, 1);
                  }

                  if (this.indice_conexao_atual + 1 < this.conexoes_selecionadas.length) {
                    console.log("Entrou na condicao 01 STATUS");
                    this.enviarNoGrupo(this.indice_conexao_atual + 1);
                  } else {
                    console.log("Entrou na condicao 02 STATUS");
                    this.enviarNoGrupo(0);
                  }
                }
              });
            });
          } else if (grupo_sorteado) {
            this.wait(() => {
              this.getGroupAdmin(grupo_sorteado.codigo, conexao.chave_api, conexao.porta, (response) => {
                if (response && response.status != "Error") {
                  console.log("Resposta do admin");

                  if (response.timeout) {
                    this.message = "Falha na listagem de grupos...";

                    if (this.indice_conexao_atual + 1 < this.conexoes_selecionadas.length) {
                      this.enviarNoGrupo(this.indice_conexao_atual + 1);
                    } else {
                      this.enviarNoGrupo(0);
                    }
                  } else {
                    this.message = "Enviando mensagem agora...";

                    let indice = Math.floor(Math.random() * this.array_mensagens.length);

                    //TESTE
                    //let indice = 1;

                    console.log("Indice sorteado");
                    console.log(indice);

                    console.log(this.array_mensagens[indice]);

                    let mensagem = this.array_mensagens[indice];

                    if (mensagem) {
                      this.enviar(
                        mensagem.tipo_envio,
                        grupo_sorteado.codigo,
                        mensagem.texto,
                        mensagem.url,
                        conexao.chave_api,
                        conexao.porta,
                        (timeout, phone, sucess) => {
                          this.getResult(timeout, phone, sucess, () => {
                            this.isLoading = true;

                            let data = {
                              container_id: this.conexoes_selecionadas[this.indice_conexao_atual].id,
                            };

                            axios
                              .post(`/aquecimento/atualizar_mensagens_enviadas`, data)
                              .then((response) => {
                                this.conexoes_selecionadas[this.indice_conexao_atual].mensagens_enviadas++;

                                if (this.indice_conexao_atual + 1 < this.conexoes_selecionadas.length) {
                                  console.log("Entrou na condicao 01");
                                  this.enviarNoGrupo(this.indice_conexao_atual + 1);
                                } else {
                                  console.log("Entrou na condicao 02");
                                  this.enviarNoGrupo(0);
                                }
                              })
                              .catch((error) => {
                                this.showErrorMessageWithButton("Ops..", error.response.data);
                                console.log(error.response.data);
                              });
                          });
                        }
                      );
                    }
                  }
                } else {
                  this.message = "Entrando no grupo...";
                  console.log("Não está no grupo, entrando...");
                  console.log(response);

                  this.joinGroup(grupo_sorteado.link_convite, conexao.chave_api, conexao.porta, (response) => {
                    console.log("Entrou no grupo");

                    if (this.indice_conexao_atual + 1 < this.conexoes_selecionadas.length) {
                      console.log("Entrou na condicao 01 pos entrada");
                      this.enviarNoGrupo(this.indice_conexao_atual + 1);
                    } else {
                      console.log("Entrou na condicao 02 pos entrada");
                      this.enviarNoGrupo(0);
                    }
                  });
                }
              });
            });
          } else {
            //this.enviarNoGrupo();
            console.log("Grupo não sorteado");
          }
        } else {
          if (this.indice_conexao_atual + 1 <= this.conexoes_selecionadas.length) {
            console.log("ELSE Entrou na condicao 01");
            this.enviarNoGrupo(this.indice_conexao_atual + 1);
          } else {
            console.log("ELSE Entrou na condicao 02");
            console.log("Indice " + this.indice_conexao_atual);
            console.log("Indice 2" + indice);
            console.log(this.conexoes_selecionadas);
            this.enviarNoGrupo(0);
          }
        }
      } else {
        this.showErrorMessageWithButton("Ops...", "Nenhuma conexão selecionada!");
      }
    },

    calcularTempoStatus(status_postado_em) {
      var dataReferencia = moment(new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }), "MM-DD-YYYY HH:mm:ss A");
      let data = moment(status_postado_em);

      console.log("\n\nComparando");
      console.log(dataReferencia);
      console.log(data);

      var duration = moment.duration(dataReferencia.diff(data));
      var hours = duration.asHours();

      console.log("Resultado comparacao");
      console.log(hours);

      if (hours < 6) {
        return false;
      } else {
        return true;
      }
    },

    getResult(timeout, phone, sucess, callback) {
      if (timeout) {
        this.logsErro.push("Falha no envio... Verifique a conexão de <b>" + this.conexoes_selecionadas[this.indice_conexao_atual].nome + "</b>");
        this.logsErro.push("Refazendo sorteio");

        this.showErrorMessage("Falha no envio... Verifique a conexão de <b>" + this.conexoes_selecionadas[this.indice_conexao_atual].nome + "</b>");

        let indice = this.findInSelectedContainers(this.conexoes_selecionadas[this.indice_conexao_atual]);

        if (indice >= 0) {
          this.conexoes_selecionadas.splice(indice, 1);
        }

        this.enviarNoGrupo();
      } else {
        //Verificando se foi enviado com sucesso
        console.log("Checking status...");

        if (sucess) {
          callback();
        } else {
          this.logsErro.push("Falha no envio... Verifique a conexão de <b>" + this.conexoes_selecionadas[this.indice_conexao_atual].nome + "</b>");
          this.logsErro.push("Refazendo sorteio");

          this.showErrorMessage("Falha no envio... Verifique a conexão de <b>" + this.conexoes_selecionadas[this.indice_conexao_atual].nome + "</b>");

          let indice = this.findInSelectedContainers(this.conexoes_selecionadas[this.indice_conexao_atual]);

          if (indice >= 0) {
            this.conexoes_selecionadas.splice(indice, 1);
          }

          this.enviarNoGrupo();
        }
      }
    },

    checkConnectedNumber(connection) {
      if (!connection.numero_conectado || connection.numero_conectado == "") {
        let indice = this.findInSelectedContainers(connection);

        console.log("Indice");
        console.log(indice);

        if (indice >= 0) {
          this.conexoes_selecionadas.splice(indice, 1);
        }

        this.showErrorMessageWithButton("Ops...", "Esta conexão não possui telefone definido.");
      }
    },

    validateTime() {
      if (this.minTime > this.maxTime) {
        this.maxTime = this.minTime;
      }

      if (this.minTime < 0) {
        this.minTime = 0;
      }

      if (this.minTimeResposta > this.maxTimeResposta) {
        this.maxTimeResposta = this.minTimeResposta;
      }

      if (this.minTimeResposta < 0) {
        this.minTimeResposta = 0;
      }
    },

    selectAll() {
      if (!this.allSelected) {
        for (let i = 0; i < this.auxContainers.length; i++) {
          if (this.auxContainers[i].numero_conectado && this.auxContainers[i].numero_conectado != "") {
            if (!this.conexoes_selecionadas.includes(this.auxContainers[i])) {
              this.conexoes_selecionadas.push(this.auxContainers[i]);
            }
          }
        }

        this.allSelected = true;
      } else {
        for (let i = 0; i < this.auxContainers.length; i++) {
          let indice = this.findInSelectedContainers(this.auxContainers[i]);

          if (indice >= 0) {
            this.conexoes_selecionadas.splice(indice, 1);
          }
        }

        this.allSelected = false;
      }
    },

    findInSelectedContainers(id) {
      let indice = this.conexoes_selecionadas.findIndex((element) => element == id);

      return indice;
    },

    compare(a, b) {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    },

    findCorrelacao(id) {
      console.log("ID ATUAL");
      console.log(id);
      let indice = this.auxContainers.findIndex((element) => element["id"] == id);

      return indice;
    },

    wait(callback) {
      this.timeToSend = this.getTime();

      let that = this;

      for (let i = 0; i <= this.timeToSend; i++) {
        setTimeout(function () {
          if (that.timeToSend > 0) {
            that.messageRemetente = "Enviando de <b style='color: #22ca80'>" + that.conexoes_selecionadas[that.indice_conexao_atual].nome + "</b>";
            that.message = "Próxima ação em " + that.timeToSend + " segundos";
            that.timeToSend -= 1;
          } else {
            callback();
          }
        }, i * 1000);
      }
    },
    //FIM DA COMBINAÇÃO

    getTime() {
      let time = Math.floor(Math.random() * (this.maxTime - this.minTime + 1)) + this.minTime;

      return time;
    },

    getTimeResposta() {
      let time = Math.floor(Math.random() * (this.maxTimeResposta - this.minTimeResposta + 1)) + this.minTimeResposta;

      return time;
    },

    redirect(page) {
      window.location.href = page;
    },

    findFunil(id) {
      let indice = this.funis.findIndex((element) => element["id"] == id);

      return indice;
    },

    findMensagemPadrao(id) {
      let indice = this.mensagens_padrao.findIndex((element) => element["id"] == id);

      return indice;
    },

    validateFields() {
      this.message = "";
      this.messageRespostaTempo = "";
      this.messageResposta = "";
      this.messageRemetente = "";

      if (this.conexoes_selecionadas == 0) {
        this.showErrorMessageWithButton("Ops...", "selecione as conexões para aquecimento!");
      } else if (this.conexoes_selecionadas.length < 2) {
        this.showErrorMessageWithButton("Ops...", "O aquecimento precisa de pelo menos 2 conexões!");
      } else {
        this.enviarNoGrupo();
      }
    },
  },
};
</script>

<style scoped>
.fire {
  font-size: 15px;
  background: rgb(254, 181, 1);
  background: linear-gradient(90deg, rgba(254, 181, 1, 1) 29%, rgba(255, 211, 2, 1) 46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.enviando {
  background: #22ca80 !important;
  color: white !important;
}

.recebendo {
  background: rgb(52, 52, 223) !important;
  color: white !important;
}

.acompanhamento {
  margin-left: 30px;
  margin: 10px;
  padding: 10px;
  width: 100%;
}

.loop {
  color: red;
}

.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr:nth-child(even) {
  background: #fff;
}

table tr:nth-child(odd) {
  background: #eee;
}

table.pdi tr:nth-child(even) {
  background: #fff;
}

table.pdi tr:nth-child(odd) {
  background: #80f0af;
}

table caption {
  font-size: 1.2em;
  margin: 0.1em 0 0.5em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.1em;
}

table th,
table td {
  padding: 0.2em;
  text-align: center;
  font-size: 0.9em;
}

table th {
  font-size: 0.7em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    padding: 0.4em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.9em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.cursor {
  cursor: pointer;
}
</style>
