require('./bootstrap');

import { createApp } from "vue";
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import App from "./components/App.vue";
import VueClipboard from 'vue-clipboard2'

import Show from "./components/backoffice/show.vue";

//USER
import UserIndex from "./components/user/Index.vue";
import MensagemPadrao from "./components/user/MensagemPadrao.vue";

//ADMIN
import RelatorioPorConexao from "./components/admin/RelatorioPorConexao.vue";
import AdminContainersList from "./components/admin/containers/Listar.vue";
import AdminContainerCreate from "./components/admin/containers/Criar.vue";
import AdminContainerEdit from "./components/admin/containers/Editar.vue";
import CreateUser from "./components/admin/CreateUser.vue";
import EditUser from "./components/admin/EditUser.vue";
import ListUsers from "./components/admin/ListUsers.vue";
import EnvioAdmin from "./components/envios/EnvioAdmin.vue";

//WHATSAPP CONNECTION COMPONENTS
import QrCodeIndicazap from "./components/indicazap/QrCodeIndicazap.vue";
import RelatorioListaContatos from "./components/indicazap/RelatorioListaContatos.vue";
import QrCodeButton from "./components/whatsapp/qrCodeButton.vue";
import DeleteTokenButton from "./components/whatsapp/deleteTokenButton.vue";
import ConnectionStatus from "./components/whatsapp/connectionStatus.vue";
import ConnectionStatusAdmin from "./components/whatsapp/connectionStatusAdmin.vue";
import Load from "./components/whatsapp/Load.vue";

//SEND COMPONENTS
import Create from "./components/envios/Create.vue";
import Send from "./components/envios/Send.vue";
import SendClickmassa from "./components/envios/SendClickmassa.vue";
import List from "./components/envios/List.vue";
import Continue from "./components/envios/Continue.vue";
import Sequence from "./components/envios/CreateSequence.vue";
import SequenceAdmin from "./components/envios/CreateSequenceAdmin.vue";
import Aquecimento from "./components/envios/Aquecimento.vue";
import AquecimentoGrupos from "./components/envios/AquecimentoGrupos.vue";

//LISTAR FORMULÁRIOS
import AdminListarFormularios from "./components/admin/formularios/Listar.vue";
import AdminCriarFormulario from "./components/admin/formularios/Criar.vue";
import AdminEditarFormulario from "./components/admin/formularios/Editar.vue";

//LISTAR LEADS
import AdminListarLeads from "./components/admin/leads/Listar.vue";
import AdminCriarLead from "./components/admin/leads/Criar.vue";
import AdminEditarLeads from "./components/admin/leads/Editar.vue";

//UPLOAD FILE
import UploadFile from "./components/gallery/UploadToGallery.vue";
import ListFiles from "./components/gallery/ListFiles.vue";

//INTEGRAÇÕES
import IntegracaoGestormed from "./components/integracoes/IntegracaoGestormed.vue";
import IntegracaoFeegow from "./components/integracoes/IntegracaoFeegow.vue";

//MENSAGENS PADRÃO
import ListMensagens from "./components/mensagens_padrao/ListMensagens.vue";
import CreateMensagens from "./components/mensagens_padrao/CreateMensagem.vue";

//CRIAR FUNIL
import CreateFunil from "./components/envios/CreateFunil.vue";
import ListarFunis from "./components/funil/List.vue";
import EditarFunil from "./components/funil/Edit.vue";

//RESPOSTA AUTOMÁTICA
import RespostaAutomatica from "./components/user/RespostaAutomatica.vue";

//SERVIDORES
import Servidores from "./components/admin/Servidores.vue";
import Restart from "./components/whatsapp/restart.vue";

//BLACKLISTS
import ListarBlacklists from "./components/blacklist/ListarBlacklists.vue";
import CriarBlacklist from "./components/blacklist/CriarBlacklist.vue";

//GRUPOS 
import ListarGrupos from "./components/grupos/ListarGrupos.vue";

//USER LISTA CONNECTIONS 
import ListConnections from "./components/user/ListConnections.vue";
import CreateConnection from "./components/user/CreateConnection.vue";

//PLANILHAS
import ListarPlanilhas from "./components/planilhas/Listar.vue";
import CadastrarPlanilhas from "./components/planilhas/Cadastrar.vue";

//AÇÕES PERSONALIZADAS
import ListarAcoesPersonalizadas from "./components/acoes_personalizadas/Listar.vue";
import CadastrarAcoesPersonalizadas from "./components/acoes_personalizadas/Cadastrar.vue";

//CAMPANHA
import CriarCampanha from "./components/campanha/Create.vue";

//CAMPANHA
import ExtrairContatos from "./components/extrair_contatos/ExtrairContatos.vue";

//CAMPANHA
import RelatorioClientesFunil from "./components/admin/funis/RelatorioClientesFunil.vue";

//CHAT
//import Chat from "./components/chat/Index.vue";


//ADMIN INDICAZAP
import AdminIndicazap from "./components/admin-indicazap/Backoffice.vue";
import ListarPromotores from "./components/admin-indicazap/ListarPromotores.vue";
import CadastrarPromotor from "./components/admin-indicazap/CadastrarPromotor.vue";
import ListarCampanha from "./components/admin-indicazap/ListarCampanha.vue";
import CadastrarCampanha from "./components/admin-indicazap/CadastrarCampanha.vue";
import AdminIndicazapRelatorio from "./components/admin-indicazap/Relatorio.vue";
import RelatorioPromotor from "./components/indicazap/RelatorioPromotor.vue";


//FORMULÁRIO
import formulario from "./components/formulario/CadastrarLead.vue";

import axios from 'axios';
import VueAxios from 'vue-axios'


//OPÇÃO 3
const vue = createApp({
    components: {
        List, Show
    }
})

vue.use(VueAxios, axios)
vue.use(VueClipboard)
vue.use(VueSweetalert2);
vue.use(router)
vue.component('relatorio-clientes-funil', RelatorioClientesFunil)
vue.component('extrair-contatos', ExtrairContatos)
vue.component('formulario', formulario)
vue.component('admin-edit-formulario', AdminEditarFormulario)
vue.component('admin-create-formulario', AdminCriarFormulario)
vue.component('admin-list-formularios', AdminListarFormularios)
vue.component('admin-edit-lead', AdminEditarLeads)
vue.component('admin-create-lead', AdminCriarLead)
vue.component('admin-list-leads', AdminListarLeads)
vue.component('admin-relatorio-conexao', RelatorioPorConexao)
vue.component('admin-create-container', AdminContainerCreate)
vue.component('admin-edit-container', AdminContainerEdit)
vue.component('admin-list-containers', AdminContainersList)
vue.component('envio-admin', EnvioAdmin)
vue.component('criar-campanha', CriarCampanha)
vue.component('relatorio-promotor', RelatorioPromotor)
vue.component('admin-indicazap-relatorio', AdminIndicazapRelatorio)
vue.component('admin-indicazap', AdminIndicazap)
vue.component('listar-promotores', ListarPromotores)
vue.component('cadastrar-promotor', CadastrarPromotor)
vue.component('listar-campanha', ListarCampanha)
vue.component('cadastrar-campanha', CadastrarCampanha)
vue.component('listar-acoes-personalizadas', ListarAcoesPersonalizadas)
vue.component('criar-acoes-personalizadas', CadastrarAcoesPersonalizadas)
vue.component('listar-planilhas', ListarPlanilhas)
vue.component('criar-planilhas', CadastrarPlanilhas)
vue.component('create-connection', CreateConnection)
vue.component('list-connections', ListConnections)
vue.component('send-clickmassa', SendClickmassa)
vue.component('listar-blacklists', ListarBlacklists)
vue.component('criar-blacklist', CriarBlacklist)
//vue.component('chat', Chat)
vue.component('mylist', List)
vue.component('myshow', Show)
vue.component('load', Load)
vue.component('grupos', ListarGrupos)
vue.component('qr-code', QrCodeButton)
vue.component('qr-code-indicazap', QrCodeIndicazap)
vue.component('relatorio-lista-contatos', RelatorioListaContatos)
vue.component('delete-token', DeleteTokenButton)
vue.component('connection-status', ConnectionStatus)
vue.component('admin-connection-status', ConnectionStatusAdmin)
vue.component('create-send', Create)
vue.component('aquecimento', Aquecimento)
vue.component('aquecimento-grupos', AquecimentoGrupos)
vue.component('send', Send)
vue.component('list', List)
vue.component('create-sequence', Sequence)
vue.component('create-sequence-admin', SequenceAdmin)
vue.component('admin-create-user', CreateUser)
vue.component('admin-edit-user', EditUser)
vue.component('admin-list-users', ListUsers)
vue.component('upload-file', UploadFile)
vue.component('list-files', ListFiles)
vue.component('user-index', UserIndex)
vue.component('continue', Continue)
vue.component('integracao-gestormed', IntegracaoGestormed)
vue.component('integracao-feegow', IntegracaoFeegow)
vue.component('admin-create-mensagens-padrao', CreateMensagens)
vue.component('admin-mensagens-padrao', ListMensagens)
vue.component('user-mensagens', MensagemPadrao)
vue.component('criar-funil', CreateFunil)
vue.component('listar-funis', ListarFunis)
vue.component('editar-funil', EditarFunil)
vue.component('resposta-automatica', RespostaAutomatica)
vue.component('servidores', Servidores)
vue.component('restart-connection', Restart)

vue.mount("#app")


//OPÇÃO 2
/*
const vue = createApp(App)
vue.use(router)
vue.component('mylist', List)
vue.mount("#app")
*/

//OPÇÃO 1
//createApp(App).use(router).mount("#app")

